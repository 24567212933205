<!--指标-->
<template>
  <div class="container data-box">
    <b-card class="col-ms-12 use-data" header="填报固废资源化技术信息">
      <div class="step">
        <el-steps direction="vertical" :active="view.stepActive">
          <el-step title="基础信息"></el-step>
          <el-step title="资源消耗"></el-step>
          <el-step title="资源产出"></el-step>
          <el-step title="环境影响"></el-step>
          <el-step title="经济指标"></el-step>
          <el-step title="社会指标"></el-step>
          <el-step title="其他说明"></el-step>
          <el-step title="评价结果"></el-step>
        </el-steps>
      </div>

      <el-card class="form" shadow="always"
               :style="{display: (view.stepActive === 0 ? 'block' : 'none')}">
        <h3>基础信息</h3>
        <p>请输入固废资源化技术（项目）的基础信息</p>
        <el-form :model="techForm" :rules="rules" ref="form0" label-width="30%">
          <el-form-item label="填报单位" prop="fillingUnit">
            <el-tooltip class="item" effect="light" content="填写此《技术信息采集表》的机构" placement="top">
              <el-input v-model="techForm.fillingUnit"></el-input>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="技术信息采集责任人" prop="personLiable">
            <el-tooltip class="item" effect="light" content="填写此《技术信息采集表》的负责人" placement="top">
              <el-input v-model="techForm.personLiable"></el-input>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="联系方式" prop="applicationUnit">
            <el-tooltip class="item" effect="light" content="填写此《技术信息采集表》负责人的联系电话" placement="top">
              <el-input v-model="techForm.applicationUnit"></el-input>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="技术应用单位地址" prop="unitAddress">
            <el-tooltip class="item" effect="light" content="填写开发或应用本项技术的单位" placement="top">
              <el-input v-model="techForm.unitAddress"></el-input>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="固废来源" prop="sources">
            <el-select
                v-model="techForm.source"
                placeholder="请选择"
                @change="queryWasteBigSelect"
            >
              <el-option
                  v-for="item in options.wasteSource"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="固废大类" prop="wasteBigTypes">
            <el-select
                v-model="techForm.wasteType"
                placeholder="请选择"
                @change="queryWasteSmallSelect"
            >
              <el-option
                  v-for="item in options.wasteBigType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="固废小类" prop="wasteSmallTypes">
            <el-select
                v-model="techForm.wasteSubtype"
                placeholder="请选择"
            >
              <el-option
                  v-for="item in options.wasteSmallType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="资源化产品大类" prop="productBigTypes">
            <el-select
                v-model="techForm.resourceType"
                placeholder="请选择"
                @change="queryProductSmallSelect"
            >
              <el-option
                  v-for="item in options.productBigType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="资源化产品小类" prop="productSmallTypes">
            <el-select
                v-model="techForm.resourceSubtype"
                placeholder="请选择"
                @change="queryProductTechSelect"
            >
              <el-option
                  v-for="item in options.productSmallType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="固废资源化技术名称" prop="techName">
            <el-tooltip class="item" effect="light" content="填写开发或应用本项技术单位声称的技术名称" placement="top">
              <el-input v-model="techForm.techName"/>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="填报时间" prop="fillingTime">
            <el-tooltip class="item" effect="light" content="选择填写时间" placement="top">
              <el-date-picker
                  v-model="techForm.fillingTime"
                  type="date"
                  value-format="yyyy-MM-dd hh:mm:ss"
                  placeholder="填写时间">
              </el-date-picker>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="项目投产时间" prop="productionTime">
            <el-tooltip class="item" effect="light" content="选择项目实际投产的时间" placement="top">
              <el-date-picker
                  v-model="techForm.productionTime"
                  type="date"
                  value-format="yyyy-MM-dd hh:mm:ss"
                  placeholder="项目实际投产的时间">
              </el-date-picker>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="技术就绪度自评" prop="techReadiness">
            <el-tooltip class="item" effect="light"
                        content="第1级，未通过中试验证；第2级，通过中试验证或技术示范/工程示范；第3级，通过第三方评估或用户验证认可；第4级，通过专业技术评估和成果鉴定；第5级，得到全面推广应用"
                        placement="top">
              <el-input-number v-model="techForm.techReadiness" :min="1" :max="5"
                               label="请选择技术就绪度"></el-input-number>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="年固废处理规模(吨)" prop="treatmentScale">
            <el-tooltip class="item" effect="light"
                        content="填写固废资源化项目的设计处理规模（或处理能力）；可研中同处理量相同" placement="top">
              <el-input v-model="techForm.treatmentScale"></el-input>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="年处理固废总量(吨)" prop="treatmentTotal">
            <el-tooltip class="item" effect="light"
                        content="填写固废资源化项目的实际处理规模（或处理量）；可研中同设计规模相同" placement="top">
              <el-input v-model="techForm.treatmentTotal"></el-input>
            </el-tooltip>
          </el-form-item>
        </el-form>
      </el-card>

      <el-card class="form" shadow="always"
               :style="{display: (view.stepActive === 1 ? 'block' : 'none')}">
        <h3>资源消耗</h3>
        <p>请输入固废资源化技术（项目）的资源消耗</p>
        <el-form :model="techForm" :rules="rules" ref="form1" label-width="30%">
          <div
              v-for="(item,index) in techForm.accessories"
              :key="index"
          >
            <el-form-item :label="'原辅料'+(index+1)+'名称'" prop="name">
              <el-input v-model="item.name"></el-input>
            </el-form-item>
            <el-form-item :label="'原辅料'+(index+1)+'消耗量'" prop="numerical">
              <el-input v-model="item.value"></el-input>
            </el-form-item>
          </div>
          <el-form-item label="新鲜水（自来水）消耗量(立方米/年)" prop="tapWasterConsumption">
            <el-tooltip class="item" effect="dark" content="只填写企业使用的自来水或新鲜水量，此处不考虑场内中水使用量"
                        placement="top">
              <el-input v-model="techForm.tapWasterConsumption"></el-input>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="项目占地面积(平方米)" prop="areaCovered">
            <el-tooltip class="item" effect="dark" content="只填写项目厂房、道路的占地面积，不含附属绿地的占地面积"
                        placement="top">
              <el-input v-model="techForm.areaCovered"></el-input>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="电消耗量(千瓦时/年)" prop="electricityConsumption">
            <el-tooltip class="item" effect="dark" content="只填写从电网输入的用电总量，不含厂内发电量" placement="top">
              <el-input v-model="techForm.electricityConsumption"></el-input>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="原煤消耗量(吨/年)" prop="coalConsumption">
            <el-tooltip class="item" effect="dark" content="填写燃煤消耗量" placement="top">
              <el-input v-model="techForm.coalConsumption"></el-input>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="天然气消耗量(m3)" prop="naturalGasConsumption">
            <el-tooltip class="item" effect="dark" content="填写天然气消耗量，不含厂内生产并自用的天然气（沼气）"
                        placement="top">
              <el-input v-model="techForm.naturalGasConsumption"></el-input>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="蒸汽消耗量(立方米/年)" prop="steamConsumption">
            <el-tooltip class="item" effect="dark" content="填写蒸汽消耗量" placement="top">
              <el-input v-model="techForm.steamConsumption"></el-input>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="柴油消耗量(吨/年)" prop="dieselConsumption">
            <el-tooltip class="item" effect="dark" content="填写柴油消耗量，不含厂内生产并自用的柴油" placement="top">
              <el-input v-model="techForm.dieselConsumption"></el-input>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="汽油消耗(吨/年)" prop="oilConsumption">
            <el-tooltip class="item" effect="dark" content="填写汽油消耗量，不含厂内生产并自用的汽油" placement="top">
              <el-input v-model="techForm.oilConsumption"></el-input>
            </el-tooltip>
          </el-form-item>
          <!--<el-form-item label="其他能源名称" prop="name">
            <el-tooltip class="item" effect="dark" content="请先填写具体的能源物质；单位统一为吨标准煤" placement="top">
              <el-input v-model="techForm.otherGasConsumption"></el-input>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="其他能源消耗" prop="name">
            <el-tooltip class="item" effect="dark" content="请先填写具体的能源物质；单位统一为吨标准煤" placement="top">
              <el-input></el-input>
            </el-tooltip>
          </el-form-item>-->
        </el-form>
      </el-card>

      <el-card class="form" shadow="always"
               :style="{display: (view.stepActive === 2 ? 'block' : 'none')}">
        <h3>资源产出</h3>
        <p>请输入固废资源化技术（项目）的资源产出</p>
        <el-form :model="techForm" :rules="rules" ref="form2" label-width="40%">
          <el-form-item label="进入到资源化生产过程中的固废量(吨/年)" prop="resourceWaste">
            <el-input v-model="techForm.resourceWaste"></el-input>
          </el-form-item>
          <!--        <el-form-item label="天然气产出量(m³)" prop="naturalGasProduction">
                    <el-input v-model="techForm.naturalGasProduction"></el-input>
                  </el-form-item>-->
          <el-form-item label="甲烷产生量(立方米/年)" prop="copperProduction">
            <el-input v-model="techForm.copperProduction"></el-input>
          </el-form-item>
          <div
              v-for="(item,index) in techForm.resourceProduct"
              :key="index"
          >
            <el-form-item :label="'资源化产品'+(index+1)" prop="name">
              <el-input v-model="techForm.resourceProduct[index]"></el-input>
            </el-form-item>
          </div>
          <el-form-item label="循环水利用量(吨)" prop="circulatingWaterConsumption">
            <el-input v-model="techForm.circulatingWaterConsumption"></el-input>
          </el-form-item>
          <el-form-item label="余热利用量(W)" prop="wasteHeatRecovery">
            <el-input v-model="techForm.wasteHeatRecovery"></el-input>
          </el-form-item>
        </el-form>
      </el-card>

      <el-card class="form" shadow="always"
               :style="{display: (view.stepActive === 3 ? 'block' : 'none')}">
        <h3>环境影响</h3>
        <p>请输入固废资源化技术（项目）的环境影响</p>
        <el-form :model="techForm" :rules="rules" ref="form3" label-width="40%">
          <el-form-item label="废气年排放总量(立方米/年)" prop="gasTotal">
            <el-input v-model="techForm.gasTotal"></el-input>
          </el-form-item>
          <el-form-item label="二氧化硫年排放总量(吨/年)" prop="so2Total">
            <el-input v-model="techForm.so2Total"></el-input>
          </el-form-item>
          <el-form-item label="氮氧化物年排放总量(吨/年)" prop="noxTotal">
            <el-input v-model="techForm.noxTotal"></el-input>
          </el-form-item>
          <div
              v-for="(item,index) in techForm.otherGasConsumption"
              :key="index"
          >
            <el-form-item :label="'其他大气污染物年排放总量'+(index+1)" prop="name">
              <el-input v-model="techForm.otherGasConsumption[index]"></el-input>
            </el-form-item>
          </div>
          <el-form-item label="废水年排放总量(立方米/年)" prop="sewageTotal">
            <el-input v-model="techForm.sewageTotal"></el-input>
          </el-form-item>
          <el-form-item label="化学需氧量（COD)年排放总量(吨/年)" prop="codTotal">
            <el-input v-model="techForm.codTotal"></el-input>
          </el-form-item>
          <el-form-item label="化学需氧量（BOD)年排放总量(吨/年)" prop="bodTotal">
            <el-input v-model="techForm.bodTotal"></el-input>
          </el-form-item>
          <el-form-item label="氨氮（NH3-N)年排放总量(吨/年)" prop="nh3nTotal">
            <el-input v-model="techForm.nh3nTotal"></el-input>
          </el-form-item>
          <el-form-item label="总磷（P)年排放总量(吨/年)" prop="phosphorusTotal">
            <el-input v-model="techForm.phosphorusTotal"></el-input>
          </el-form-item>
          <div
              v-for="(item,index) in techForm.wasterOtherTotal"
              :key="index"
          >
            <el-form-item :label="'其他水环境污染物年排放量'+(index+1)" prop="wasterOtherTotal">
              <el-input v-model="techForm.wasterOtherTotal[index]"></el-input>
            </el-form-item>
          </div>
          <el-form-item label="固废（废渣）年排放总量(吨/年)" prop="wasteDischarge">
            <el-input v-model="techForm.wasteDischarge"></el-input>
          </el-form-item>
          <!-- <div
               v-for="(item,index) in techForm.wasterOtherTotal"
               :key="index"
           >
             <el-form-item :label="'固废（废渣）中的污染物'+(index+1)+'年排放量'" prop="wasterOtherTotal">
               <el-input v-model="item"></el-input>
             </el-form-item>
           </div>-->
        </el-form>
      </el-card>

      <el-card class="form" shadow="always"
               :style="{display: (view.stepActive === 4 ? 'block' : 'none')}">
        <h3>经济指标</h3>
        <p>请输入固废资源化技术（项目）的经济指标</p>
        <el-form :model="techForm" :rules="rules" ref="form4" label-width="40%">
          <el-form-item label="总投资建设费用(万元)" prop="constructionCost">
            <el-input v-model="techForm.constructionCost"></el-input>
          </el-form-item>
          <el-form-item label="年运行成本(万元/年)" prop="operatingCost">
            <el-input v-model="techForm.operatingCost"></el-input>
          </el-form-item>
          <el-form-item label="销售收入(万元/年)" prop="salesRevenue">
            <el-input v-model="techForm.salesRevenue"></el-input>
          </el-form-item>
          <el-form-item label="净利润(万元/年)" prop="netProfit">
            <el-input v-model="techForm.netProfit"></el-input>
          </el-form-item>
          <el-form-item label="净现值(万元)" prop="netPresentValue">
            <el-input v-model="techForm.netPresentValue"></el-input>
          </el-form-item>
          <el-form-item label="专利价值(万元)" prop="patentValue">
            <el-input v-model="techForm.patentValue"></el-input>
          </el-form-item>
        </el-form>
      </el-card>

      <el-card class="form" shadow="always"
               :style="{display: (view.stepActive === 5 ? 'block' : 'none')}">
        <h3>社会指标</h3>
        <p>请输入固废资源化技术（项目）的社会指标</p>
        <el-form :model="techForm" :rules="rules" ref="form5" label-width="40%">
          <el-form-item label="提供就业岗位数(个)" prop="offerJobTotal">
            <el-input v-model="techForm.offerJobTotal"></el-input>
          </el-form-item>
          <el-form-item label="循环经济示范性(是/否)" prop="circularEconomy">
            <!--          <el-input v-model="techForm.circularEconomy"></el-input>-->
            <el-select v-model="techForm.circularEconomy">
              <el-option
                  v-for="item in options.bool"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="职工可能暴露在腐蚀/有毒化学品环境下(是/否)" prop="inToxicCorrosion">
            <el-select v-model="techForm.inToxicCorrosion">
              <el-option
                  v-for="item in options.bool"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="职工可能暴露在泼溅条件下(是/否)" prop="inHighSpeed">
            <el-select v-model="techForm.inHighSpeed">
              <el-option
                  v-for="item in options.bool"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="职工可能暴露在高温表面下(是/否)" prop="inHighTemperature">
            <el-select v-model="techForm.inHighTemperature">
              <el-option
                  v-for="item in options.bool"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="职工可能暴露在高速部件下(是/否)" prop="inSplash">
            <el-select v-model="techForm.inSplash">
              <el-option
                  v-for="item in options.bool"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="职工可能暴露在高压电下(是/否)" prop="inHighVoltage">
            <el-select v-model="techForm.inHighVoltage">
              <el-option
                  v-for="item in options.bool"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="职工可能暴露在高分贝噪声下(是/否)" prop="inHighDecibel">
            <el-select v-model="techForm.inHighDecibel">
              <el-option
                  v-for="item in options.bool"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="职工可能暴露在难闻气味下(是/否)" prop="inOdorousGas">
            <el-select v-model="techForm.inOdorousGas">
              <el-option
                  v-for="item in options.bool"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="职工可能暴露在充满灰尘工作条件下(是/否)" prop="inDust">
            <el-select v-model="techForm.inDust">
              <el-option
                  v-for="item in options.bool"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="公众对技术实施的态度(百分制)" prop="publicRecognition">
            <!--          <el-input v-model="techForm.publicRecognition"></el-input>-->
            <el-input-number v-model="techForm.publicRecognition" :min="0" :max="100"></el-input-number>
          </el-form-item>
          <el-form-item label="职工平均工资(万元/年)" prop="avgWage">
            <el-input v-model="techForm.avgWage"></el-input>
            <!--          <el-select v-model="techForm.avgWage">
                        <el-option
                            v-for="item in options.bool"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                      </el-select>-->
          </el-form-item>
        </el-form>
      </el-card>

      <el-card class="form" shadow="always"
               :style="{display: (view.stepActive === 6 ? 'block' : 'none')}">
        <h3>其他说明</h3>
        <p>请输入固废资源化技术（项目）的其他说明</p>
        <el-form :model="techForm" :rules="rules" ref="form6" label-width="40%">
          <el-form-item label="其他说明">
            <el-input type="textarea" v-model="techForm.explain"></el-input>
          </el-form-item>
        </el-form>
      </el-card>

      <el-card class="form" shadow="always"
               :style="{display: (view.stepActive === 7 ? 'block' : 'none')}">
        <h3>评价结果</h3>
        <div ref="charts" class="echarts-content"></div>

        <div ref="charts2" class="echarts-content"></div>
      </el-card>

      <div class="oper">
        <el-button type="primary" @click="after">上一步</el-button>
        <el-button v-if="view.stepActive < 6" type="primary" style="float: right" @click="next">下一步</el-button>
        <el-button v-if="view.stepActive == 6" type="primary" style="float: right" @click="submit">提交</el-button>
      </div>
    </b-card>

    <b-card class="col-ms-12 explain" header="使用说明">{{ this.instructions}}</b-card>
  </div>
</template>
<script>
import {
  getWasteSourceSelect,
  getWasteBigTypeSelect,
  getWasteSmallTypeSelect,
  getProductBigSelect,
  getProductSmallSelect,
  getProductTechSelect
} from "@/api/common"
import {submitTechData} from '@/api/product'
import {len, required, float} from '@/libs/formRules'

export default {
  name: 'country',
  props: {
    msg: String
  },
  data() {
    return {
      view: {
        charts: '',
        stepActive: 0,
      },
      techForm: {
        source: '',
        wasteType: '',
        wasteSubtype: '',
        resourceType: '',
        resourceSubtype: '',
        wasteTechtype: '',
        techName: '',
        fillingUnit: '',
        personLiable: '',
        applicationUnit: '',
        unitAddress: '',
        fillingTime: '',
        productionTime: '',
        techReadiness: '',
        treatmentScale: '',
        treatmentTotal: '',
        accessories: [
          {name: '', value: ''}, {name: '', value: ''}, {name: '', value: ''}, {name: '', value: ''}, {
            name: '',
            value: ''
          }
          , {name: '', value: ''}, {name: '', value: ''}, {name: '', value: ''}, {name: '', value: ''}, {
            name: '',
            value: ''
          }
        ],
        resourceProduct: ['', '', '', '', '', '', '', ''],
        otherGasConsumption: ['', '', '', '', '', ''],
        electricityConsumption: '',
        coalConsumption: '',
        naturalGasConsumption: '',
        steamConsumption: '',
        dieselConsumption: '',
        oilConsumption: '',
        petrolConsumption: '',
        tapWasterConsumption: '',
        areaCovered: '',
        resourceWaste: '',
        naturalGasProduction: '',
        circulatingWaterConsumption: '',
        wasteHeatRecovery: '',
        copperProduction: '',
        gasTotal: '',
        so2Total: '',
        noxTotal: '',
        nmhcTotal: '',
        otherGasTotal: '',
        sewageTotal: '',
        codTotal: '',
        bodTotal: '',
        nh3nTotal: '',
        constructionCost: '',
        operatingCost: '',
        salesRevenue: '',
        netProfit: '',
        netPresentValue: '',
        offerJobTotal: '',
        patentValue: '',
        riskSources: '',
        avgWage: '',
        phosphorusTotal: '',
        wasterOtherTotal: ['', '', '', '', '', ''],
        wasteDischarge: '',
        circularEconomy: '',
        inToxicCorrosion: '',
        inHighSpeed: '',
        inHighTemperature: '',
        inSplash: '',
        inHighVoltage: '',
        inHighDecibel: '',
        inOdorousGas: '',
        inDust: '',
        publicRecognition: '',
        explain: ''
      },
      rules: {
        fillingUnit: [
          {required: true, message: '请输入填报单位', trigger: 'blur'},
        ],
        personLiable: [
          {required: true, message: '请输入信息采集负责人', trigger: 'blur'},
        ],
        applicationUnit: [
          {required: true, message: '请输入联系方式', trigger: 'blur'},
        ],
        unitAddress: [
          {required: true, message: '请输入技术应用单位地址', trigger: 'blur'},
        ],
        wasteType: [
          {required: true, message: '请选择固废大类', trigger: 'blur'},
        ],
        resourceType: [
          {required: true, message: '请选择资源化产品大类', trigger: 'blur'},
        ],
        techName: [
          {required: true, message: '请选择资源化技术名称', trigger: 'blur'},
        ],
        fillingTime: [
          {required: true, message: '填报时间', trigger: 'blur'},
        ],
        productionTime: [
          {required: true, message: '项目投产时间', trigger: 'blur'},
        ],
        techReadiness: [
          {required: true, message: '技术就绪度', trigger: 'blur'},
        ],
        treatmentScale: [
          {required: true, message: '固废设计处理规模', trigger: 'blur'},
          float
        ],
        treatmentTotal: [
          {required: true, message: '年处理固废总量', trigger: 'blur'},
          float
        ],
        tapWasterConsumption: [
          {required: false, message: '新鲜水（自来水）消耗量', trigger: 'blur'},
          float
        ],
        areaCovered: [
          {required: true, message: '项目占地面积', trigger: 'blur'},
          float
        ],
        electricityConsumption: [
          {required: false, message: '电消耗量', trigger: 'blur'},
          float
        ],
        coalConsumption: [
          {required: false, message: '原煤消耗量', trigger: 'blur'},
          float
        ],
        naturalGasConsumption: [
          {required: false, message: '天然气消耗量', trigger: 'blur'},
          float
        ],
        steamConsumption: [
          {required: false, message: '蒸汽消耗量', trigger: 'blur'},
          float
        ],
        dieselConsumption: [
          {required: false, message: '柴油消耗量', trigger: 'blur'},
          float
        ],
        oilConsumption: [
          {required: false, message: '汽油消耗', trigger: 'blur'},
          float
        ],
        otherGasConsumption: [
          {required: false, message: '其他大气污染物年排放总量', trigger: 'blur'},
          float
        ],
        resourceWaste: [
          {required: true, message: '进入到资源化生产过程中的固废量', trigger: 'blur'},
          float
        ],
        naturalGasProduction: [
          {required: true, message: '天然气产生量', trigger: 'blur'},
          float
        ],
        circulatingWaterConsumption: [
          {required: true, message: '循环水利用量', trigger: 'blur'},
          float
        ],
        wasteHeatRecovery: [
          {required: true, message: '余热利用量', trigger: 'blur'},
          float
        ],
        copperProduction: [
          {required: false, message: '铜产出量', trigger: 'blur'},
          float
        ],
        phosphorusTotal: [
          {required: true, message: '总磷排放量', trigger: 'blur'},
          float
        ],
        /*wasterOtherTotal: [
          {required: true, message: '铜产出量', trigger: 'blur'},
        ],*/
        wasteDischarge: [
          {required: true, message: '固废（废渣）年排放总量', trigger: 'blur'},
          float
        ],
        gasTotal: [
          {required: true, message: '废气年排放总量', trigger: 'blur'},
          float
        ],
        so2Total: [
          {required: true, message: '二氧化硫年排放总量', trigger: 'blur'},
          float
        ],
        noxTotal: [
          {required: true, message: '氮氧化物年排放总量', trigger: 'blur'},
          float
        ],
        sewageTotal: [
          {required: true, message: '废水年排放总量', trigger: 'blur'},
          float
        ],
        codTotal: [
          {required: true, message: '化学需氧量（COD)年排放总量', trigger: 'blur'},
          float
        ],
        bodTotal: [
          {required: true, message: '化学需氧量（BOD)年排放总量', trigger: 'blur'},
          float
        ],
        nh3nTotal: [
          {required: true, message: '氨氮（NH3-N)年排放总量', trigger: 'blur'},
          float
        ],
        constructionCost: [
          {required: true, message: '总投资建设费用', trigger: 'blur'},
          float
        ],
        operatingCost: [
          {required: true, message: '年运行成本', trigger: 'blur'},
          float
        ],
        salesRevenue: [
          {required: true, message: '销售收入', trigger: 'blur'},
          float
        ],
        netProfit: [
          {required: true, message: '净利润', trigger: 'blur'},
          float
        ],
        netPresentValue: [
          {required: false, message: '净现值', trigger: 'blur'},
          float
        ],
        offerJobTotal: [
          {required: true, message: '提供就业岗位数', trigger: 'blur'},
          float
        ],
        circularEconomy: [
          {required: false, message: '循环经济示范性', trigger: 'blur'},
        ],
        inToxicCorrosion: [
          {required: true, message: '职工可能暴露在腐蚀/有毒化学品环境下', trigger: 'blur'},
        ],
        inHighSpeed: [
          {required: true, message: '职工可能暴露在泼溅条件下', trigger: 'blur'},
        ],
        inHighTemperature: [
          {required: true, message: '职工可能暴露在高温表面下', trigger: 'blur'},
        ],
        inSplash: [
          {required: true, message: '职工可能暴露在高速部件下', trigger: 'blur'},
        ],
        inHighVoltage: [
          {required: true, message: '职工可能暴露在高压电下', trigger: 'blur'},
        ],
        inHighDecibel: [
          {required: true, message: '职工可能暴露在高分贝噪声下', trigger: 'blur'},
        ],
        inOdorousGas: [
          {required: true, message: '职工可能暴露在难闻气味下', trigger: 'blur'},
        ],
        inDust: [
          {required: true, message: '职工可能暴露在充满灰尘工作条件下', trigger: 'blur'},
        ],
        publicRecognition: [
          {required: false, message: '公众对技术实施的态度', trigger: 'blur'},
        ],
        avgWage: [
          {required: false, message: '职工平均工资', trigger: 'blur'},
          float
        ],
        explain: [
          {required: false, message: '其他说明', trigger: 'blur'},
        ],
        name: [len(50)],
        numerical: [float],
      },
      options: {
        wasteSource: [],
        wasteBigType: [],
        wasteSmallType: [],
        productBigType: [],
        productSmallType: [],
        productTech: [],
        map: {},
        bool: [{
          label: '是',
          value: 1
        }, {
          label: '否',
          value: 0
        }]
      },
      mapOptions: {
        title: {
          text: '技术评价雷达图'
        },
        tooltip: {},
        legend: {
          data: ['技术']
        },
        radar: {
          // shape: 'circle',
          name: {
            textStyle: {
              color: '#fff',
              backgroundColor: '#999',
              borderRadius: 3,
              padding: [3, 5]
            }
          },
          indicator: [
            {name: '资源影响（R）', max: 100},
            {name: '环境影响（En）', max: 100},
            {name: '经济影响（Ec）', max: 100},
            {name: '社会影响（S）', max: 100},
          ]
        },
        series: [{
          name: '技术',
          type: 'radar',
          // areaStyle: {normal: {}},
          data: [
            {
              value: [90, 89, 95, 100],
              name: '11'
            }
          ]
        }]
      },
      mapOptions2: {
        title: {
          text: '技术评价对比图'
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        toolbox: {
          feature: {
            dataView: {show: true, readOnly: false},
            magicType: {show: true, type: ["line", "bar"]},
            restore: {show: true},
            saveAsImage: {show: true},
          },
        },
        legend: {
          data: ["资源效率", "环境影响", "经济可行", "社会效应"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        axisLabel: {
          interval: 0,
          formatter: function (value) {
            var ret = ""; //拼接加\n返回的类目项
            var maxLength = 8; //每项显示文字个数
            var valLength = value.length; //X轴类目项的文字个数
            var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
            if (rowN > 1) {
              //如果类目项的文字大于5,
              for (var i = 0; i < rowN; i++) {
                var temp = ""; //每次截取的字符串
                var start = i * maxLength; //开始截取的位置
                var end = start + maxLength; //结束截取的位置
                //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
                temp = value.substring(start, end) + "\n";
                ret += temp; //凭借最终的字符串
              }
              return ret;
            } else {
              return value;
            }
          },
        },
        xAxis: [
          {
            type: "category",
            data: [],
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [],
      },
      instructions: ''
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.initChart()
      this.queryWasteSourceSelect()
      this.queryProductBigSelect()
      this.queryInstructions()
    },
    initChart() {
      // debugger
      this.view.charts = this.$echarts.init(this.$refs.charts);
      this.view.charts.setOption(this.mapOptions);
    },
    queryInstructions(){
      let item = window.localStorage.getItem("instructions")
      this.instructions = JSON.parse(item)['technical_evaluation']
    },
    after() {
      if (this.view.stepActive-- === 0) this.view.stepActive = 0;
    },
    next() {
      this.$refs["form" + this.view.stepActive].validate(valid => {
        if (valid) {
          if (this.view.stepActive++ > 6) this.view.stepActive = 0;
        }
      })
    },
    queryWasteSourceSelect() {
      getWasteSourceSelect().then(data => {
        this.options.wasteSource = data
      })
    },
    queryWasteBigSelect() {
      // debugger
      if (this.techForm.source.length === 0) return
      getWasteBigTypeSelect(this.techForm.source).then(data => {
        this.options.wasteBigType = data
      })
    },
    queryWasteSmallSelect() {
      if (this.techForm.wasteType.length === 0) return
      getWasteSmallTypeSelect(this.techForm.wasteType).then(data => {
        this.options.wasteSmallType = data
      })
    },
    queryProductBigSelect() {
      getProductBigSelect().then(data => {
        this.options.productBigType = data
      })
    },
    queryProductSmallSelect() {
      if (this.techForm.resourceType.length === 0) return
      getProductSmallSelect(this.techForm.wasteSubtype, this.techForm.resourceType).then(data => {
        this.options.productSmallType = data
      })
    },
    queryProductTechSelect(productSmallType) {
      getProductTechSelect(productSmallType).then(data => {
        this.options.productTech = data
      })
    },
    toMap(array) {
      let map = {}
      for (let i = 0; i < array.length; i++){
        const x = array[i]
        if(x.name == '' || x.value == '') continue

        map[x.name] = x.value
      }
      return map
    },
    submit() {
      let self = this
      let techForm = this.techForm
      techForm.accessories = JSON.stringify(this.toMap(techForm.accessories))
      techForm.resourceProduct = JSON.stringify(this.toMap(techForm.resourceProduct))
      techForm.wasterOtherTotal = JSON.stringify(this.toMap(techForm.wasterOtherTotal))
      techForm.otherGasConsumption = JSON.stringify(this.toMap(techForm.otherGasConsumption))
      submitTechData(this.techForm).then(data => {
        // debugger
        let {radar, compare} = data
        self.mapOptions.legend.data[0] = radar.name
        self.mapOptions.series[0].name = radar.name
        self.mapOptions.series[0].data[0].name = radar.name
        self.mapOptions.series[0].data[0].value = radar.data
        self.view.charts.setOption(self.mapOptions);

        let options = this.mapOptions2;
        options.xAxis[0].data = compare.xlabels;
        options.legend.data = compare.legends;
        for (let i = 0; i < compare.series.length; i++) {
          options.series[i] = {
            name: compare.series[i].name,
            data: compare.series[i].data,
            type: "bar",
            stack: "影响指标",
            emphasis: {
              focus: "series",
            },
            smooth: true,
          };
        }
        self.charts2 = this.$echarts.init(self.$refs.charts2);
        window.addEventListener("resize", function (event) {
          self.charts2.resize();
        })
        self.charts2.setOption(options);

        self.view.stepActive = 7
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
@import "@/assets/less/evaluate.less";

.echarts-content {
  height: 450px;
  width: 600px;
  margin-left: 20%;
}

.oper {
  margin-top: 20px;
}

.explain {
  margin-top: 1%;
  height: 300px;
}
</style>